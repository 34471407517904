var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-overlay',{attrs:{"show":_vm.loadingOverlay,"rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"lg":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('spot.cambio.titleForm')))])])],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"trocar-moeda"}},[_vm._v(_vm._s(_vm.$t('spot.cambio.labelTroca')))]),_c('validation-provider',{attrs:{"name":"Trocar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.optionsFrom,"input-id":"trocar-moeda","state":errors.length > 0 ? false:null},on:{"change":_vm.alteraMoedaFrom},model:{value:(_vm.data.moedaFrom),callback:function ($$v) {_vm.$set(_vm.data, "moedaFrom", $$v)},expression:"data.moedaFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.data.moedaFrom!=null)?_c('div',{staticClass:"font-weight-bolder mt-1"},[_vm._v(" "+_vm._s(_vm.$t('spot.cambio.textDisponivel'))+" "),_c('div',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.saldo)+" "+_vm._s(_vm.data.moedaFrom)+" ")])]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"trocar-por"}},[_vm._v(_vm._s(_vm.$t('spot.cambio.labelPor')))]),_c('validation-provider',{attrs:{"name":"Por","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.optionsTo,"input-id":"trocar-por","state":errors.length > 0 ? false:null},on:{"change":_vm.alteraMoedaTo},model:{value:(_vm.data.moedaTo),callback:function ($$v) {_vm.$set(_vm.data, "moedaTo", $$v)},expression:"data.moedaTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-alert',{attrs:{"show":_vm.data.moedaFrom == 'USDT',"variant":"warning"}},[_c('div',{staticClass:"alert-body"},[_c('span',{staticClass:"ml-25"},[_vm._v("Valor mínimo para troca "+_vm._s(_vm.minUSDT)+" USDT")])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"valor"}},[_vm._v(" "+_vm._s(_vm.$t('spot.cambio.labelValorPrimeiraParte'))+" "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.data.moedaFrom))]),_vm._v(" "+_vm._s(_vm.$t('spot.cambio.labelValorSegundaParte'))+" "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.data.moedaTo === '' ?'...' : _vm.data.moedaTo))])]),_c('validation-provider',{attrs:{"name":"Valor a trocar","rules":{ required: true, regex: /^[+]?\d+(\.\d+)?$/, min_value: _vm.moedaFromMinValue },"custom-messages":{
                        min_value: ("O valor deve ser maior ou igual a " + _vm.moedaFromMinValue + ".")
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"valor","state":errors.length > 0 ? false:null},model:{value:(_vm.data.quantia),callback:function ($$v) {_vm.$set(_vm.data, "quantia", $$v)},expression:"data.quantia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.data.moedaTo && _vm.data.quantia != 0 && errors.length == 0)?_c('div',{staticClass:"font-weight-bolder mt-1"},[_vm._v(" "+_vm._s(_vm.$t('spot.cambio.textRecebaAprox'))+" "),_c('h3',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.quantidadeAproximada)+" "+_vm._s(_vm.data.moedaTo)+" ")])]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('spot.cambio.trocaBtn'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }